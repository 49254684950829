<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{$t('pump_install.StoreReport')}}</h4>
      </template>
      <template v-slot:body>
      <b-row>
        <b-col lg="6" md="6" sm="12" xs="12">
          <b-form-group
            class="row"
            label-cols-sm="3"
            label-for="org_id"
            >
            <template v-slot:label>
            {{$t('org_pro.organization')}} <span class="text-danger">*</span>
            </template>
            <b-form-select
              plain
              v-model="search.org_id"
              :options="organizationList"
              id="org_id"
              >
              <template v-slot:first>
                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
              </template>
            </b-form-select>
            <span v-show="orgErrorMsg" class="text-danger">{{ 'Organization field is required' }}</span>
            </b-form-group>
        </b-col>
        <b-col lg="6" md="6" sm="12" xs="12">
          <b-form-group
            class="row"
            label-cols-sm="3"
            :label="$t('org_pro_division.division')"
            label-for="division_id"
            >
            <b-form-select
              plain
              v-model="search.division_id"
              :options="divisionList"
              id="division_id"
              >
              <template v-slot:first>
                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
              </template>
            </b-form-select>
            </b-form-group>
        </b-col>
        <b-col lg="6" md="6" sm="12" xs="12">
          <b-form-group
            class="row"
            label-cols-sm="3"
            :label="$t('org_pro_district.district')"
            label-for="district_id"
            >
            <b-form-select
              plain
              v-model="search.district_id"
              :options="districtList"
              id="district_id"
              >
              <template v-slot:first>
                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
              </template>
            </b-form-select>
            </b-form-group>
        </b-col>
        <b-col lg="6" md="6" sm="12" xs="12">
          <b-form-group
            class="row"
            label-cols-sm="3"
            :label="$t('org_pro_upazilla.upazilla')"
            label-for="upazilla_id"
            >
            <b-form-select
              plain
              v-model="search.upazilla_id"
              :options="upazilaList"
              id="upazilla_id"
              >
              <template v-slot:first>
                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
              </template>
            </b-form-select>
            </b-form-group>
        </b-col>
        <b-col lg="6" md="6" sm="12" xs="12">
          <b-form-group
            class="row"
            label-cols-sm="3"
            :label="$t('org_pro_union.union')"
            label-for="union_id"
            >
            <b-form-select
              plain
              v-model="search.union_id"
              :options="unionList"
              id="union_id"
              >
              <template v-slot:first>
                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
              </template>
            </b-form-select>
            </b-form-group>
        </b-col>
        <b-col lg="6" md="6" sm="12" xs="12">
          <b-form-group
            class="row"
            label-cols-sm="3"
            label-for="office_id"
            :label="$t('user.office')"
            >
            <b-form-select
              plain
              v-model="search.office_id"
              :options="officeList"
              >
              <template v-slot:first>
                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col lg="6" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="3"
              :label="$t('irrigation_config.category_namel')"
              label-for="category_id"
            >
              <b-form-select
                plain
                v-model="search.category_id"
                :options="categoryList"
                id="category_id"
                >
                <template v-slot:first>
                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col lg="6" md="6" sm="12" xs="12">
            <b-button type="button" class="btn btn-sm" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
          </b-col>
      </b-row>
      </template>
    </iq-card>
    <b-row v-show="showData">
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{$t('pump_install.StoreReport')}}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button class="btn_add_new mr-2" @click="pdfExport">
              <i class="fas fa-print"></i>{{  $t('globalTrans.print') }}
            </b-button>
            <export-excel
                class="btn btn_add_new"
                :data="dataCustomize"
                :fields= "json_fields"
                :title="$t('pump_install.StoreReport')"
                :worksheet="$t('pump_install.StoreReport')"
                name="StoreReport.xls">
                <i class="far fa-file-excel"></i>{{ $t('globalTrans.export_excel') }}
            </export-excel>
            <!-- <export-excel
                class="btn btn-danger"
                :data="dataCustomize"
                :worksheet="$t('pump_install.StoreReport')"
                :fields= "json_fields"
                type="csv"
                name="filename.xls">
                {{ $t('globalTrans.export_csv') }}
            </export-excel> -->
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table bordered hover :items="stores" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(organization)="data">
                      <span>{{ ($i18n.locale==='bn') ? data.item.org_name_bn :  data.item.org_name }}</span>
                    </template>
                    <template v-slot:cell(item)="data">
                      <span>{{ ($i18n.locale==='bn') ? data.item.item_name_bn :  data.item.item_name }}</span>
                    </template>
                    <template v-slot:cell(category)="data">
                      <span>{{ ($i18n.locale==='bn') ? data.item.category_name_bn :  data.item.category_name }}</span>
                    </template>
                    <template v-slot:cell(office)="data">
                      <span>{{ ($i18n.locale==='bn') ? data.item.office_name_bn :  data.item.office_name }}</span>
                    </template>
                    <template v-slot:cell(quantity)="data">
                      <span>{{ $n(data.item.quantity) }}</span>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import RestApi, { irriSchemeServiceBaseUrl } from '../../../../../config/api_config'
import excel from 'vue-excel-export'
import Vue from 'vue'
import ExportPdf from './export-pdf_details'
import { mapGetters } from 'vuex'
Vue.use(excel)
const excelColumn = {
  Organization: 'org',
  Office: 'office',
  Category: 'category',
  Item: 'item',
  Quantity: 'quantity'
}
const excelColumnBn = {
  সংস্থা: 'org',
  দপ্তর: 'office',
  'শ্রেণীর নাম': 'category',
  আইটেম: 'item',
  পরিমাণ: 'quantity_local'
}
export default {
  name: 'UiDataTable',
  data () {
    return {
      showData: false,
      orgErrorMsg: false,
      division_text: '',
      district_text: '',
      upazila_text: '',
      pagination: {
        currentPage: 1,
        totalRows: 0,
        perPage: this.$store.state.commonObj.perPage,
        slOffset: 1
      },
      search: {
        org_id: 0,
        division_id: 0,
        district_id: 0,
        upazilla_id: 0,
        union_id: 0,
        office_id: 0,
        category_id: 0
      },
      testId: 0,
      latitude: 0,
      longitude: 0,
      rows: [],
      stores: [],
      projectList: [],
      districtList: [],
      upazilaList: [],
      unionList: [],
      officeList: []
    }
  },
  computed: {
    ...mapGetters({
      authUser: 'Auth/authUser'
    }),
    json_fields: function () {
      return this.$i18n.locale === 'bn' ? excelColumnBn : excelColumn
    },
    categoryList: function () {
      return this.$store.state.IrriConfig.commonObj.categoryList.filter(item => item.status === 0)
    },
    organizationList: function () {
      return this.$store.state.commonObj.organizationProfileList
    },
    formTitle () {
       return (this.testId === 0) ? this.$t('irristore.pump_information_entry') : this.$t('irristore.pump_information') + ' ' + this.$t('globalTrans.modify')
    },
    detailTitle () {
       return this.$t('irristore.pump_information_detail')
    },
    mapTitle () {
       return this.$t('irristore.mapTitle')
    },
    loading () {
      return this.$store.state.commonObj.loading
    },
    listReload () {
      return this.$store.state.commonObj.listReload
    },
    loadingState () {
      if (this.listReload) {
        return true
      } else if (this.loading) {
        return true
      } else {
        return false
      }
    },
    currentLocale () {
      return this.$i18n.locale
    },
    dataCustomize () {
      const stores = this.stores
      const listContractor = stores.map(item => {
        return Object.assign({}, item, {
          category: this.$i18n.locale === 'en' ? item.category_name : item.category_name_bn,
          org: this.$i18n.locale === 'en' ? item.org_name : item.org_name_bn,
          item: this.$i18n.locale === 'en' ? item.item_name : item.item_name_bn,
          office: this.$i18n.locale === 'en' ? item.office_name : item.office_name_bn,
          quantity_local: this.$n(item.quantity)
        })
      })
      return listContractor
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('pump_install.organization'), class: 'text-left' },
          { label: this.$t('pump_install.office'), class: 'text-left' },
          { label: this.$t('pump_install.category'), class: 'text-left' },
          { label: this.$t('pump_install.item'), class: 'text-left' },
          { label: this.$t('pump_install.quantity'), class: 'text-left' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'Organization' },
          { key: 'Office' },
          { key: 'Category' },
          { key: 'Item' },
          { key: 'quantity' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'Organization' },
          { key: 'Office' },
          { key: 'Category' },
          { key: 'Item' },
          { key: 'quantity' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
    },
    divisionList: function () {
      return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
    }
  },
  watch: {
    'search.org_id': function (newVal, oldVal) {
      if (this.search.newVal !== 0) {
        this.orgErrorMsg = false
      } else {
        this.orgErrorMsg = true
      }
    },
    'search.division_id': function (newVal, oldVal) {
      this.districtList = this.getDistrictList(newVal)
    },
    'search.district_id': function (newVal, oldVal) {
      this.upazilaList = this.getUpazilaList(newVal)
    },
    'search.upazilla_id': function (newVal, oldVal) {
      this.unionList = this.getUnionList(newVal)
    },
    'search.union_id': function (newVal, oldVal) {
      this.officeList = this.getOfficeList(newVal)
    }
  },
  mounted () {
    core.index()
    if (this.authUser.role_id === 0 && this.authUser.is_org_admin === 1) {
      this.search = Object.assign({}, this.search, {
        org_id: this.authUser.org_id
      })
    } else if (this.authUser.role_id === 0) {
      this.search = Object.assign({}, this.search, {
        org_id: this.authUser.office_detail.org_id,
        far_division_id: this.authUser.office_detail.division_id,
        far_district_id: this.authUser.office_detail.district_id,
        far_upazilla_id: this.authUser.office_detail.upazilla_id
      })
    }
    this.loadData()
  },
  methods: {
    mapModalOpen (item) {
      this.latitude = item.latitude
      this.longitude = item.longitude
    },
    resetId () {
      this.testId = 0
    },
    testClick () {
      this.loadData()
    },
    searchData () {
      if (this.search.division_id) {
        const objectData = this.$store.state.commonObj.divisionList.find(item => item.value === this.search.division_id)
        this.division_text = this.$i18n.locale === 'bn' ? objectData.text_bn : objectData.text_en
      }
      if (this.search.district_id) {
        const objectData = this.$store.state.commonObj.districtList.find(item => item.value === this.search.district_id)
        this.district_text = this.$i18n.locale === 'bn' ? objectData.text_bn : objectData.text_en
      }
      if (this.search.upazilla_id) {
        const objectData = this.$store.state.commonObj.upazilaList.find(item => item.value === this.search.upazilla_id)
        this.upazila_text = this.$i18n.locale === 'bn' ? objectData.text_bn : objectData.text_en
      }

      if (this.search.org_id !== 0) {
        this.showData = true
        this.loadData()
      } else {
        this.orgErrorMsg = true
      }
    },
    loadData () {
      // const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      RestApi.getData(irriSchemeServiceBaseUrl, 'pump-installation/report/store-report', this.search).then(response => {
        if (response.success) {
          this.getRelationalData(response)
        }
      })
      //
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
     getRelationalData (data) {
      const organizationList = this.$store.state.commonObj.organizationProfileList
      const officeList = this.$store.state.commonObj.officeList
      const storeData = data.data.map(item => {
        const orgObject = organizationList.find(oganization => oganization.value === item.org_id)
        const orgData = {
          org_name: orgObject !== undefined ? orgObject.text_en : '',
          org_name_bn: orgObject !== undefined ? orgObject.text_bn : ''
        }
        const officeObject = officeList.find(office => office.value === item.office_id)
        const officeData = {
          office_name: officeObject !== undefined ? officeObject.text_en : '',
          office_name_bn: officeObject !== undefined ? officeObject.text_bn : ''
        }
        return Object.assign({}, item, orgData, officeData)
      })
      this.stores = storeData
    },
    getDistrictList (divisionId = null) {
      const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
      if (divisionId) {
        return districtList.filter(district => district.division_id === divisionId)
      }
      return districtList
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }
      return upazilaList
    },
    getUnionList (upazillaId = null) {
      const unionList = this.$store.state.commonObj.unionList.filter(item => item.status === 0)
      if (upazillaId) {
        return unionList.filter(union => union.upazilla_id === upazillaId)
      }
      return unionList
    },
    getOfficeList (unionId = null) {
      const officeList = this.$store.state.commonObj.officeList.filter(item => item.status === 0)
      if (unionId) {
        return officeList.filter(union => union.union_id === unionId)
      }
      return officeList
    },
    pdfExport () {
      const reportTitle = this.$t('pump_install.StoreReport')
      ExportPdf.exportPdfDetails(irriSchemeServiceBaseUrl, '/report-heading/detail', this.search.org_id, reportTitle, this.stores, this,
        this.division_text,
        this.district_text,
        this.upazila_text
      )
    },
    getPdfData () {
      let keys = []
      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'serial_no' },
          { key: 'org_name_bn' },
          { key: 'office_name_bn' },
          { key: 'category_name_bn' },
          { key: 'item_name_bn' },
          { key: 'quantity' }
        ]
      } else {
        keys = [
          { key: 'serial_no' },
          { key: 'org_name' },
          { key: 'office_name' },
          { key: 'category_name' },
          { key: 'item_name' },
          { key: 'quantity' }
        ]
      }
      var serial = 0
      const listData = this.stores.map(item => {
          serial += 1
          const rowData = keys.map(keyItem => {
            if (keyItem.key === 'serial_no') {
                return { text: this.$n(serial) }
            }
            return { text: item[keyItem.key] }
        })

        return rowData
      })

      return listData
    },
    getCategorylist () {
      RestApi.getData(irriSchemeServiceBaseUrl, 'item-categories/list').then(response => {
        this.categoryList = response.data.data.map((obj, index) => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.id, text: obj.category_name_bn }
          } else {
            return { value: obj.id, text: obj.category_name }
          }
        })
      })
    }
  }
}
</script>
