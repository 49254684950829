import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
// import { dateFormat } from '@/Utils/fliter'

const exportPdfDetails = async (baseUrl, uri, orgId, reportTitle, data, vm, division, district, upazila) => {
  try {
    Store.commit('mutateCommonProperties', {
      loading: true
    })
    if (i18n.locale === 'bn') {
      pdfMake.vfs = pdfFontsBn.pdfMake.vfs
    } else {
      pdfMake.vfs = pdfFontsEn.pdfMake.vfs
    }
    const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
    const pdfContent = [
      {
        columns: reportHeadData.reportHeadColumn, style: 'main_head'
      },
      {
        text: vm.$t('fertilizerReport.krishi_bhaban'),
        style: 'krishi',
        alignment: 'center'
      },
      { text: reportHeadData.address, style: 'address', alignment: 'center' }
    ]
    // pdfContent.push({ text: reportTitle, style: 'header2', alignment: 'center' })

    const allRowsHead = [
        [
        { text: vm.$t('globalTrans.division'), alignment: 'right', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: division, alignment: 'left', style: 'search' },
        { text: vm.$t('globalTrans.district'), alignment: 'right', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: district, alignment: 'left', style: 'search' }
      ]
    ]

    allRowsHead.push([
      { text: vm.$t('globalTrans.upazila'), alignment: 'right', style: 'search' },
      { text: ':', style: 'search', alignment: 'center' },
      { text: upazila, alignment: 'left', style: 'search' },
      {},
      {},
      {}
    ])

      pdfContent.push({
        table: {
          headerRows: 1,
          widths: ['24%', '2%', '24%', '24%', '2%', '24%'],
          body: allRowsHead
        },
        layout: {
          hLineWidth: function (i, node) {
            return 0
          },
          vLineWidth: function (i, node) {
            return 0
          }
        }
      })
      pdfContent.push({ text: reportTitle, style: 'header2', alignment: 'center', decoration: 'underline' })
      const headList = [
        { text: vm.$t('globalTrans.sl_no'), style: 'th', alignment: 'center' },
        { text: vm.$t('pump_install.office'), style: 'th', alignment: 'center' },
        { text: vm.$t('pump_install.category'), style: 'th', alignment: 'center' },
        { text: vm.$t('pump_install.item'), style: 'th', alignment: 'center' },
        { text: vm.$t('pump_install.quantity'), style: 'th', alignment: 'center' }
      ]

      const allRows = [headList]
      data.map((report, index) => {
        const newRow = [
          { text: vm.$n(index + 1), style: 'td', alignment: 'center' },
          { text: (i18n.locale === 'bn') ? report.office_name_bn : report.office_name, style: 'td', alignment: 'center' },
          { text: (i18n.locale === 'bn') ? report.category_name_bn : report.category_name, style: 'td', alignment: 'center' },
          { text: (i18n.locale === 'bn') ? report.item_name_bn : report.item_name, style: 'td', alignment: 'center' },
          { text: vm.$n(report.quantity), style: 'td', alignment: 'right' }
        ]
        allRows.push(newRow)
      })

      pdfContent.push({
        table: {
          headerRows: 1,
          widths: ['8%', '*', '*', '*', '*'],
          body: allRows
        }
      })
      const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
      var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'Portrait',
        watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
          th: {
            fontSize: (i18n === 'bn') ? 12 : 10,
            margin: [3, 3, 3, 3],
            bold: true
          },
          td: {
            fontSize: (i18n === 'bn') ? 12 : 10,
            margin: [3, 3, 3, 3]
          },
          search: {
            fontSize: (i18n === 'bn') ? 12 : 10,
            margin: [3, 3, 3, 3]
          },
          fertilizer: {
            margin: [0, 10, 0, 7]
          },
          fertilizerSHeader: {
              fontSize: 10,
              margin: [40, 0, 0, 0]
          },
          header: {
            fontSize: 12,
            margin: [0, 0, 0, 4]
          },
          header2: {
            fontSize: 14,
            margin: [0, 10, 0, 10]
          },
          headerPort1: {
            fontSize: 10,
            margin: [0, 20, 0, 5]
          },
          headerPort: {
            fontSize: 10,
            margin: [0, 4, 0, 15]
          },
          krishi: {
            margin: [0, -5, 0, 15],
            alignment: 'center'
          },
          header3: {
            fontSize: 9,
            margin: [0, 15, 0, 0]
          },
          address: {
            fontSize: 9,
            margin: [0, -10, 0, 0]
          },
          tableSubHead: {
            margin: [0, 5, 0, 15]
          }
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).print()
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
